@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* .ant-input-number-input-wrap .ant-input-number-input {
  text-align: right;
  padding: 0;
} */

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: rgb(244, 230, 75);
  border: 1px solid rgb(30, 30, 31);
  box-sizing: border-box;
}

[class*="price-analysis-row-0"] td {
  border-top: 1px solid black !important;
}
